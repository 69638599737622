<template>
    <div>
        <s-form @submit="addItem">
        <h2 class="head font-poppins-semibold fs-lg-4 mt-3 text-primary">Vaccination</h2>
        <div class="row mt-4">
            <div class="col-lg-2 mt-4">
                <validated-ajax-vue-select
                    :url="vaccinataionOptionURL"
                    label="Vaccinataion Type" class="c-input-select text-black field-required" v-model="model.vaccination_type" :rules="{required:true}"></validated-ajax-vue-select>
            </div>
            <div class="col-lg-2 mt-4">
                <validated-date-picker format="DD-MM-YYYY" label="Vaccination Date" class="text-black c-input-datepicker field-required"  v-model="model.vaccination_date" :rules="rules.FututreValidation"></validated-date-picker>
            </div>
            <div class="col-lg-2 mt-4">
                <validated-date-picker format="DD-MM-YYYY" label="Next Due Date" class="text-black c-input-datepicker field-required" v-model="model.next_due_date" :rules="rules.common"></validated-date-picker>
            </div>
<!--            <div class="col-lg-2">-->
<!--                <validated-checkbox label="Seromonitoring (Post)" class="pb-0 mt&#45;&#45;2 mb-xl-n4 text-2"></validated-checkbox>-->
<!--                <validated-input></validated-input>-->
<!--            </div>-->
            <div class="col-lg-2 pl-3 w-10r mt-3">
                    <validated-checkbox label="Seromonitoring(post)" class="pb-0 mb-xl-n4 text-black" v-model="model.seromonitoring"></validated-checkbox>
                <validated-input></validated-input>
                </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-2">
                <validated-ajax-vue-select
                    :url="shedOptionURL" multiple
                    label="Shed" class="c-input-select text-black field-required" v-model="model.shedno" @input="loadCowOptions" :rules="{required:true}"></validated-ajax-vue-select>
            </div>
            <div class="col-lg-2">
                <validated-vue-select :options="cowOptions" label="Animal" class="c-input-select text-black field-required" v-model="model.cow" :rules="{required:true}"></validated-vue-select>
            </div>
            <div class="col-lg-2">
                <validated-input label="Vaccine Name" class="text-black" v-model="model.vaccination_name"></validated-input>
            </div>
            <div class="col-lg-2">
                <validated-input label="Vaccine Batch No" class="text-black" v-model="model.vaccination_batch_no"></validated-input>
            </div>
            <div class="col-lg-2">
                <validated-input label="Remarks" class="text-black" v-model="model.remarks"></validated-input>
            </div>
        </div>
        <div class="row mt-lg-7">
            <div class="col-12">
                <div class="fl-x fl-j-e">
                    <div class="btn-group">
                        <btn text="Save" :loading="loading" loading-text="Saving..." class="px-4"></btn>
                        <btn text="Cancel" type="reset" @click="BackToHome" design="basic-b" class="px-4"></btn>
                        </div>
                </div>
            </div>
        </div>
            </s-form>
    </div>
</template>

<script>
import axios from 'secure-axios';
import masterURLs from '../../data/masterURLs';
import urls from '../../data/urls';

export default {
    name: 'CowVaccination',
    data () {
        return {
            loading: false,
            URL: urls.cowVaccination.addEdit,
            vaccinataionOptionURL: masterURLs.master.VaccinationType.vueSelect,
            shedOptionURL: masterURLs.master.shed.vueSelect + '?animal=cow',
            cowOptions: [],
            model: {
                cow: '',
                shed_number: '',
                next_due_date: '',
                seromonitoring: '',
                vaccination_name: '',
                vaccination_batch_no: '',
                remarks: '',
                vaccination_type: '',
                vaccination_date: ''
            },
            rules: {

                common: {
                    required: true,
                    customValidator: (value) => {
                        return this.Validation(value);
                    }
                },
                FututreValidation: {
                    required: true,
                    customValidator: (value) => {
                        return this.ValidationFuture(value);
                    }
                }
            }
        };
    },
    methods: {
        ValidationFuture (date) {
            const dateParts = date.split('-');
            if (dateParts.length !== 3) {
                return 'Please enter a valid AI date in the format DD-MM-YYYY.';
            }
            const aiDay = parseInt(dateParts[0], 10);
            const aiMonth = parseInt(dateParts[1], 10);
            const aiYear = parseInt(dateParts[2], 10);
            if (isNaN(aiDay) || isNaN(aiMonth) || isNaN(aiYear)) {
                return 'Please enter valid dates in the format DD-MM-YYYY.';
            }

            const dateObj = new Date(aiYear, aiMonth - 1, aiDay);
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

            if (dateObj > currentDate) {
                return 'Please enter a date from today onwards.';
            }
            return true;
        },

        BackToHome () {
            this.$router.push({ path: '/ah-section/' });
        },
        async loadCowOptions () {
            this.model.cow = '';
            const response = await axios.get(urls.cows.vueSelect + '?shed_number=' + this.model.shedno);
            this.cowOptions = response.data.data;
        },

        Validation (date) {
            const vacc = this.model.vaccination_date;
            const dateParts = date.split('-');
            if (dateParts.length !== 3) {
                return 'Please enter a valid AI date in the format DD-MM-YYYY.';
            }
            const aiDay = parseInt(dateParts[0], 10);
            const aiMonth = parseInt(dateParts[1], 10);
            const aiYear = parseInt(dateParts[2], 10);

            const vaccParts = vacc.split('-');
            if (vaccParts.length !== 3) {
                return 'Please enter a valid DOB in the format DD-MM-YYYY.';
            }
            const vaccDay = parseInt(vaccParts[0], 10);
            const vaccMonth = parseInt(vaccParts[1], 10);
            const vaccYear = parseInt(vaccParts[2], 10);

            if (isNaN(aiDay) || isNaN(aiMonth) || isNaN(aiYear) || isNaN(vaccDay) || isNaN(vaccMonth) || isNaN(vaccYear)) {
                return 'Please enter valid dates in the format DD-MM-YYYY.';
            }

            const dateObj = new Date(aiYear, aiMonth - 1, aiDay);
            const vaccDateObj = new Date(vaccYear, vaccMonth - 1, vaccDay);

            if (dateObj <= vaccDateObj) {
                return 'Date should be after the date of Vaccination Date.';
            }
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            if (dateObj < currentDate) {
                return 'Please enter a date from today onwards.';
            }
            return true;
        },
        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                if (result.success) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.$router.push({ path: '/cows-list/' });
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage = '';
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }

};
</script>

<style scoped>
</style>
